import React from "react";

// Components
import { Global } from "@emotion/core";
import Layout from "../../components/Layouts/Layout";
import SEO from "../../components/Utilities/SEO";
import SectionProduct from "../../components/Sections/SectionProduct";
import ImgProductJournal1 from "../../components/Images/Journal/products/ImgProductJournal1";

const data = {
  category: "Journal",
  images: [<ImgProductJournal1 />],
  title: "Lord, Teach Us To Pray Prayer Journal",
  description:
    "Dedicated to Sherri Parsons, Sweetness, a dedicated Garden Prayer Warrior. This journal is embossed with the Anana Vestige logo to remind you Wear Your Crown, Live a Beautiful Lifestyle, Regardless! The disciples might have used a journal like this soft felt Tucson Ivory Notebook made by The Castelli Family, Bergamo, Italy. Dimensions: medium size 130x210mm, designed for temporaneous notes. Forest green colored, 240 ruled paged with same colored elastic band closure, & pen loop. Classic gold ribbon. Ivory FSC ecological paper sourced from sustainable forests with rounded corner edges. Back pocket for notes, pictures, small memorabilia to share or study.",
  price: 39.99,
  link: ""
};

export default () => {
  return (
    <Layout scrollLock>
      <SEO
        title="Know Believe Fulfill | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <Global
        styles={{
          ".nav-item-journal": {
            color: "white"
          }
        }}
      />
      <SectionProduct data={data} />
    </Layout>
  );
};
